import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "ga-4-react";

const TRACKING_ID = "G-DWZG43PRCV";
const GA4 = new ReactGA(TRACKING_ID);

(async () => {
  await GA4.initialize().then((ga4) => {
    ga4.pageview('path')
    ga4.gtag('event', 'pageview', 'path') // or your custom gtag event
  }, (err) => {
    console.error(err)
  })

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
